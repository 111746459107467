
import owlCarousel from 'owl.carousel';

function markenSlider() {
    var owl = jQuery('.marken-slider-inner');
    //var itemCount = jQuery(owl).find('.stage').length;

    owl.owlCarousel({
        //animateOut: 'fadeOut',
        nav: true,
        navText: ['<span class="owl-carousel-pfeil">&larr;</span>', '<span class="owl-carousel-pfeil">&rarr;</span>'],
        mouseDrag: false,
        touchDrag: true,
        loop: true,
        dots: false,
        margin: 10,
        stagePadding: 50,
        autoplay: true,
        smartSpeed: 750,
        stagePadding: 0,
        items: 5,
        lazyLoad: true,
        responsive: {
            0: {
                items: 1
            },
            992: {
                items: 3
            },
            1440: {
                items: 5
            }
        }
    });
}

export { markenSlider };

function newsSlider() {
    var owl = jQuery('.news-slider-inner');
    //var itemCount = jQuery(owl).find('.stage').length;

    owl.owlCarousel({
        //animateOut: 'fadeOut',
        nav: true,
        navText: ['<span class="owl-carousel-pfeil">&#129040;</span>', '<span class="owl-carousel-pfeil">&#129042;</span>'],
        mouseDrag: false,
        touchDrag: true,
        loop: true,
        dots: false,
        margin: 10,
        stagePadding: 50,
        autoplay: true,
        smartSpeed: 750,
        stagePadding: 0,
        items: 4,
        lazyLoad: true,
        responsive: {
            0: {
                items: 1
            },
            992: {
                items: 2
            },
            1440: {
                items: 4
            }
        }
    });
}

export { newsSlider };


function produktSlider() {
    var owl = jQuery('.produkt-slider-inner');
    //var itemCount = jQuery(owl).find('.stage').length;

    owl.owlCarousel({
        //animateOut: 'fadeOut',
        nav: true,
        navText: ['<span class="owl-carousel-pfeil">&#129040;</span>', '<span class="owl-carousel-pfeil">&#129042;</span>'],
        mouseDrag: false,
        touchDrag: true,
        loop: true,
        dots: false,
        margin: 10,
        stagePadding: 50,
        autoplay: true,
        smartSpeed: 750,
        stagePadding: 0,
        items: 4,
        lazyLoad: true,
        responsive: {
            0: {
                items: 1
            },
            992: {
                items: 2
            },
            1440: {
                items: 4
            }
        }
    });
}

export { produktSlider };
