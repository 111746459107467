import jQuery from 'jquery';
import '@fancyapps/fancybox';
import 'bootstrap';
import "animate.css";
import "owl.carousel";
import {WOW} from 'wowjs';
import { lazyload } from './globals/lazyload';
import { gutenbergColumnClasses } from './globals/gutenbergColumnClasses';
import { toggleNavigation } from './layout/navigation';
import { toggleSearch } from './layout/search';
import { markenSlider } from './partials/slider';
import { newsSlider } from './partials/slider';
import { produktSlider } from './partials/slider';
import { accordion } from './partials/accordion';
import { enableForm } from './partials/form';
import { testimonials } from './partials/testimonials';
import { scrollTo } from './partials/scrollTo';
//import { groupInsertFrame } from './partials/groupInsertFrame';



jQuery(document).ready(_ => {
    lazyload();
    markenSlider();
    newsSlider();
    produktSlider();
    //gutenbergColumnClasses();
    toggleNavigation();
    toggleSearch();
    accordion();
    enableForm();
    testimonials();
    scrollTo();
    new WOW().init();
    //groupInsertFrame();

});
